<template>
  <div
    class="merchantReports"
    v-loading.fullscreen.lock="loading || loadingTime"
    element-loading-text="加载中..."
  >
    <div class="wrap">
      <ul
        class="tabsPanel fl"
        v-if="pageIndex!==2"
      >
        <li
          v-for="item in tabsOptions"
          :key="item.id"
          :class="{'activeItem': activeItem === item.id}"
          @click="handleTbs(item.id)"
        >{{item.label}}</li>
      </ul>
      <ul
        class="filtroPanel fr w-input-item w-input-sty"
        v-if="pageIndex!==2"
      >
        <!-- <li>
          <el-select
            popper-class="w-block-select-down"
            v-model="projectId"
            size="small"
            placeholder="请选择"
            @change="handleData"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </li> -->
        <li>
          <el-date-picker
            popper-class="black-el-date-picker"
            v-model="filtroDate"
            size="small"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            :type="datePickerOptions[activeItem-1]"
            :format="activeItem===3?'yyyy-MM':valueDate"
            value-format="yyyy-MM-dd"
            @change="handleData"
            :clearable='false'
          >
          </el-date-picker>
        </li>
        <li class="fs12">
          时间：{{params.startDate +' 至 '+ params.endDate}}
        </li>
      </ul>
      <div class="clearfix"></div>
      <div v-if="pageIndex===0">
        <div class="simpleInfoPanel">
          <ul class="projectDataPanel">
            <li
              v-for="item in projectData"
              :key="item.index"
              :title="item.title === '招商完成率' ? '招商完成率：已签订正式合同的铺位建筑面积（去重）/所有铺位建筑面积' : ''"
            >
              <h2>{{item.value}}<span v-if="item.index===0">%</span></h2>
              <div class="liTitle">{{item.title}}</div>
              <p>
                相对{{dateTextArr[activeItem-1]}}：
                <span :class="{
                    'cls-green': (item.compared>0),
                    'cls-orange': (item.compared<0)
                  }">
                  {{item.compared===0?'持平':(item.compared > 0 ? '+' : ' ') + item.compared +'%'}}
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div class="simpleInfoPanel">
          <h2 class="w-title-gradients">品牌招商{{dateTextArr1[activeItem-1]}}变化数据</h2>
          <ul
            class="brandMerchantsDataPanel commonUl"
            v-if="Object.keys(brandMerchantsData).length"
          >
            <li class="border-r pr80">
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandMerchantsData.investmentBrandNum === null ? '--' : brandMerchantsData.investmentBrandNum}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，新增加了<span class="cls-active">{{brandMerchantsData.investmentBrandNum === null ? '--' : brandMerchantsData.investmentBrandNum}}个</span>招商品牌
                </p>
                <div class="clearfix"></div>
              </div>
              <h3 class="subTitle">
                新增招商品牌
                <span @click="brandMerchantsDataIsShowMore0=!brandMerchantsDataIsShowMore0">
                  <i :class="{
                    'icon-showMore': !brandMerchantsDataIsShowMore0,
                    'icon-showLess': brandMerchantsDataIsShowMore0,
                  }"></i>
                  {{brandMerchantsDataIsShowMore0?'收起':'展开'}}
                </span>
              </h3>
              <div
                class="listSpan"
                v-if="brandMerchantsData.investmentBrand && brandMerchantsData.investmentBrand.length"
                :class="{'showLess': !brandMerchantsDataIsShowMore0}"
              >
                <span
                  v-for="(item,index) in brandMerchantsData.investmentBrand"
                  @click="toDetail(item.taskId)"
                  :key="index"
                >
                  {{item.brandName || '--'}}
                </span>
              </div>
              <p v-else>--</p>
            </li>
            <li>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandMerchantsData.investmentScheduleNum === null ? '--' : brandMerchantsData.investmentScheduleNum}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，共有<span class="cls-active">{{brandMerchantsData.investmentScheduleNum === null ? '--' : brandMerchantsData.investmentScheduleNum}}个</span>品牌的招商进度和跟进状态发生变化
                </p>
                <div class="clearfix"></div>
              </div>
              <h3 class="subTitle">
                招商进度状态发生变化的品牌
                <span @click="brandMerchantsDataIsShowMore1=!brandMerchantsDataIsShowMore1">
                  <i :class="{
                    'icon-showMore': !brandMerchantsDataIsShowMore1,
                    'icon-showLess': brandMerchantsDataIsShowMore1,
                  }"></i>
                  {{brandMerchantsDataIsShowMore1?'收起':'展开'}}
                </span>
              </h3>
              <div
                class="listSpan"
                v-if="brandMerchantsData.investmentSchedule && brandMerchantsData.investmentSchedule.length"
                :class="{'showLess': !brandMerchantsDataIsShowMore1}"
              >
                <span
                  v-for="(item,index) in brandMerchantsData.investmentSchedule"
                  @click="toDetail(item.taskId)"
                  :key="index"
                >
                  {{item.brandName || '--'}}
                  <i
                    class="icon-circle"
                    :style="{background: item.color}"
                  ></i>
                  {{item.taskState || '--'}}
                </span>
              </div>
              <p v-else>--</p>
            </li>
          </ul>
        </div>
        <div class="simpleInfoPanel">
          <h2 class="w-title-gradients">团队成员{{dateTextArr1[activeItem-1]}}招商数据</h2>
          <ul
            class="brandMerchantsDataPanel commonUl"
            v-if="Object.keys(memberMerchantsNum).length"
          >
            <li>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{memberMerchantsNum.maxFollowNum || '--'}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  从跟进品牌的数量来看，在
                  {{dateIntervalStr||'--'}}
                  “<span class="cls-active">{{memberMerchantsNum.maxFollowMember || '--'}}</span>”
                  跟进品牌数量最多，变更了{{memberMerchantsNum.maxFollowNum || '--'}}个品牌招商进度（{{memberMerchantsNum.maxFollowBrands || '--'}}）
                </p>
              </div>
            </li>
            <li>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{memberMerchantsNum.maxSignedNum || '--'}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  从最终签约品牌数量来看，在
                  {{dateIntervalStr||'--'}}
                  “<span class="cls-active">{{memberMerchantsNum.maxSignedMember || '--'}}</span>”
                  最终签约品牌数量最多，成功签约{{memberMerchantsNum.maxSignedNum || '--'}}个品牌（{{memberMerchantsNum.maxSignedBrands || '--'}}）
                </p>
              </div>
            </li>
          </ul>
          <div class="wrapBox">
            <h3 class="subTitle fl">团队成员品牌招商跟进数量对比</h3>
            <el-select
              class="fr"
              popper-class="w-block-select-down"
              v-model="statesVal"
              @change="getDataMemberCharts"
              placeholder="请选择"
            >
              <el-option
                v-for="item in statesOption"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
            <div class="clearfix"></div>
            <div
              v-show="hasDataMemberMerchantsNumCharts"
              id="chartsMember"
              class="chartsBox"
            ></div>
            <div
              class="details-no-data"
              v-show="!hasDataMemberMerchantsNumCharts"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
            <div class="clearfix"></div>
            <h3
              class="subTitle"
              v-if="Object.keys(memberMerchantsNum).length"
            >
              团队成员品牌招商数据列表
              <span
                v-if="memberMerchantsNumListLong.length>3"
                @click="handleMemberMerchantsNumList"
              >
                <i :class="{
                  'icon-showMore': !memberMerchantsNumList.length<=3,
                  'icon-showLess': memberMerchantsNumList.length>3,
                }"></i>
                {{memberMerchantsNumList.length>3?'收起':'展开'}}
              </span>
            </h3>
            <table
              v-if="hasDataMemberMerchantsNum"
              class="commonTbs"
            >
              <tr
                v-for="item in memberMerchantsNumList"
                :key="item.userid"
              >
                <td
                  width="136"
                  class="tbsTh pointer"
                  @click="toWorkReport(item.userid, item.userName)"
                >{{item.userName}}</td>
                <td
                  width="160"
                  class="noBorder-r pointer"
                  @click="toWorkReport(item.userid, item.userName)"
                >
                  跟进{{item.num || '--'}}个，已签约{{item.signedNum || '--'}}个
                </td>
                <td
                  class="noBorder-l pointer"
                  @click="toWorkReport(item.userid, item.userName)"
                >
                  <span
                    v-for="(ele,index) in item.scheduleList"
                    :key="index"
                  >
                    <i
                      class="icon-circle"
                      :style="{background: ele.color}"
                    ></i>
                    {{ele.title || '--'}}
                  </span>
                </td>
              </tr>
            </table>
            <div
              class="details-no-data"
              v-show="!hasDataMemberMerchantsNum"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
        <div class="simpleInfoPanel">
          <div class="fl wrapPer50 border-r-x">
            <h2 class="w-title-gradients fl">新增签约品牌分布</h2>
            <el-radio-group
              class="fr filtroRight"
              size="medium"
              v-model="newSignedBrandFiltroValue"
              @change="getDataNewSignedBrand"
            >
              <el-radio-button
                v-for="(item,index) in newSignedBrandFiltroOption"
                :key="index"
                :label="item"
              ></el-radio-button>
            </el-radio-group>
            <div class="clearfix"></div>
            <div
              v-show="hasDataNwsSignedBrand"
              id="chartsNewSignedBrand"
              class="chartsBox"
            ></div>
            <div
              class="details-no-data chartsNoData"
              v-show="!hasDataNwsSignedBrand"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
            <div class="wrapBox">
              <h3 class="subTitle mt-35">签约品牌列表</h3>
              <table
                class="commonTbs"
                v-if="
                  Object.keys(newSignedBrandData).length &&
                  hasDataNwsSignedBrand"
              >
                <tr
                  v-for="item in signedBrandList"
                  :key="item.userid"
                >
                  <td
                    width="100"
                    class="tbsTh pointer"
                    @click="toDetail(item.taskId, {
                      contractCode: item.contractCode,
                      contractId: item.contractId
                    })"
                  >{{item.brandName || '--'}}</td>
                  <td
                    width="100"
                    class="noBorder-r"
                  >
                    {{item.floorBerthNo || '--'}}
                  </td>
                  <td
                    width="100"
                    class="noBorder-r noBorder-l"
                  >
                    {{item.userName || '--'}}
                  </td>
                  <td class="noBorder-l">
                    {{item.createTime || '--'}}
                  </td>
                </tr>
                <tr v-if="newSignedBrandData.brandListScheduleList && newSignedBrandData.brandListScheduleList.length>5">
                  <td
                    align="center"
                    colspan="4"
                    class="pointer"
                    @click="handleTbsLenght(0)"
                  >
                    {{signedBrandList.length>5?'收起':'展开全部'}}
                    <i :class="{
                      'icon-showLess': signedBrandList.length>5,
                      'icon-showMore': signedBrandList.length<=5
                    }"></i>
                  </td>
                </tr>
              </table>
              <div
                class="details-no-data"
                v-else
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
          <div class="fr wrapPer50">
            <h2 class="w-title-gradients fl">确认无意向/搁置品牌分布</h2>
            <el-radio-group
              class="fr filtroRight"
              size="medium"
              v-model="noIntentionBrandFiltroValue"
              @change="getDataNoIntentionBrand"
            >
              <el-radio-button
                v-for="(item,index) in newSignedBrandFiltroOption"
                :key="index"
                :label="item"
              ></el-radio-button>
            </el-radio-group>
            <div class="clearfix"></div>
            <div
              v-show="
                Object.keys(noIntentionBrandData).length &&
                noIntentionBrandData.chartItemList &&
                noIntentionBrandData.chartItemList.length>0
              "
              id="chartsNoIntentionBrand"
              class="chartsBox"
            ></div>
            <div
              class="details-no-data chartsNoData"
              v-show="
                noIntentionBrandData.chartItemList &&
                noIntentionBrandData.chartItemList.length === 0"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
            <div class="wrapBox">
              <h3 class="subTitle mt-35">确认无意向/搁置品牌分布</h3>
              <table
                class="commonTbs"
                v-if="
                  Object.keys(noIntentionBrandData).length &&
                  noIntentionBrandData.brandListScheduleList &&
                  noIntentionBrandData.brandListScheduleList.length
                "
              >
                <tr
                  v-for="item in noIntentionBrandList"
                  :key="item.userid"
                >
                  <td
                    width="100"
                    class="tbsTh pointer"
                    @click="toDetail(item.taskId)"
                  >{{item.brandName || '--'}}</td>
                  <td
                    width="100"
                    class="noBorder-r"
                  >
                    {{item.userName || '--'}}
                  </td>
                  <td
                    width="100"
                    class="noBorder-r noBorder-l"
                  >
                    {{item.followRemark || '--'}}
                  </td>
                  <td class="noBorder-l">
                    {{item.createTime || '--'}}
                  </td>
                </tr>
                <tr v-if="noIntentionBrandData.brandListScheduleList && noIntentionBrandData.brandListScheduleList.length>5">
                  <td
                    align="center"
                    colspan="4"
                    class="pointer"
                    @click="handleTbsLenght(1)"
                  >
                    {{noIntentionBrandList.length>5?'收起':'展开全部'}}
                    <i :class="{
                      'icon-showLess': noIntentionBrandList.length>5,
                      'icon-showMore': noIntentionBrandList.length<=5
                    }"></i>
                  </td>
                </tr>
              </table>
              <div
                class="details-no-data"
                v-else
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="simpleInfoPanel">
          <h2 class="w-title-gradients fl">项目招商{{dateTextArr1[activeItem-1]}}进度数据</h2>
          <el-radio-group
            class="fr filtroRight"
            size="medium"
            v-model="projectProgressFiltroValue"
            @change="getDataProjectProgress"
          >
            <el-radio-button
              v-for="item in projectProgressFiltroOption"
              :key="item.value"
              :label="item.label"
            >{{item.name}}</el-radio-button>
          </el-radio-group>
          <div class="clearfix"></div>
          <div class="wrapBox">
            <div class="fl wrapPer50 border-r">
              <h3 class="subTitle mt-35">业态招商完成率变化</h3>
              <div
                v-show="hasDataChartsFormat"
                id="chartsComplexFormat"
                class="chartsBox"
              ></div>
              <p
                v-show="hasDataChartsFormat"
                class="chartsExplain"
                v-html="formatExplain"
              ></p>
              <div
                class="details-no-data"
                v-show="!hasDataChartsFormat"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
            <div class="fr wrapPer46">
              <h3 class="subTitle mt-35">楼层招商完成率变化</h3>
              <div
                v-show="hasDataChartsFloor"
                id="chartsComplexFloor"
                class="chartsBox"
              ></div>
              <p
                v-show="hasDataChartsFloor"
                class="chartsExplain"
                v-html="floorExplain"
              ></p>
              <div
                class="details-no-data"
                v-show="!hasDataChartsFloor"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div v-if="pageIndex===1">
        <div class="simpleInfoPanel">
          <h2 class="w-title-gradients">品牌招商数据统计</h2>
          <ul
            class="brandMerchantsDataPanel commonUl"
            v-if="Object.keys(brandMerchantsData).length"
          >
            <li class="border-r pr80">
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandMerchantsData.newMerchantsQuantity===null?'--': brandMerchantsData.newMerchantsQuantity}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，{{reportUserName}}主动新增加了<span class="cls-active">{{brandMerchantsData.newMerchantsQuantity===null?'--': brandMerchantsData.newMerchantsQuantity}}个</span>招商品牌
                </p>
                <div class="clearfix"></div>
              </div>
              <h3 class="subTitle">
                新增招商品牌
                <span @click="brandMerchantsDataIsShowMore0=!brandMerchantsDataIsShowMore0">
                  <i :class="{
                    'icon-showMore': !brandMerchantsDataIsShowMore0,
                    'icon-showLess': brandMerchantsDataIsShowMore0,
                  }"></i>
                  {{brandMerchantsDataIsShowMore0?'收起':'展开'}}
                </span>
              </h3>
              <div
                class="listSpan"
                v-if="brandMerchantsData.newMerchantsNameList && brandMerchantsData.newMerchantsNameList.length"
                :class="{'showLess': !brandMerchantsDataIsShowMore0}"
              >
                <span
                  v-for="(item,index) in brandMerchantsData.newMerchantsNameList"
                  :key="index"
                  @click="toDetail(item.taskId)"
                >
                  {{item.brandName || '--'}}
                </span>
              </div>
              <p v-else>--</p>
            </li>
            <li>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandMerchantsData.merchantsChangeQuantity === null? '--' : brandMerchantsData.merchantsChangeQuantity}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，{{reportUserName}}共对<span class="cls-active">{{brandMerchantsData.merchantsChangeQuantity === null? '--' : brandMerchantsData.merchantsChangeQuantity}}个</span>品牌的招商进度和跟进状态进行过更新
                </p>
                <div class="clearfix"></div>
              </div>
              <h3 class="subTitle">
                招商进度状态发生变化的品牌
                <span @click="brandMerchantsDataIsShowMore1=!brandMerchantsDataIsShowMore1">
                  <i :class="{
                    'icon-showMore': !brandMerchantsDataIsShowMore1,
                    'icon-showLess': brandMerchantsDataIsShowMore1,
                  }"></i>
                  {{brandMerchantsDataIsShowMore1?'收起':'展开'}}
                </span>
              </h3>
              <div
                class="listSpan"
                v-if="brandMerchantsData.investmentSchedule && brandMerchantsData.investmentSchedule.length"
                :class="{'showLess': !brandMerchantsDataIsShowMore1}"
              >
                <span
                  v-for="(item,index) in brandMerchantsData.investmentSchedule"
                  :key="index"
                  @click="toDetail(item.taskId)"
                >
                  {{item.brandName || '--'}}
                  <i
                    class="icon-circle"
                    :style="{background: item.color}"
                  ></i>
                  {{item.taskState || '--'}}
                </span>
              </div>
              <p v-else>--</p>
            </li>
          </ul>
        </div>
        <div
          class="simpleInfoPanel box624"
          v-loading="!hasDataBrandSigned"
        >
          <div class="title-flex">
            <div>
              <h2 class="w-title-gradients">品牌签约数据统计</h2>
            </div>
            <div>
              <h2 class="w-title-gradients">无意向/搁置品牌统计</h2>
            </div>
          </div>
          <ul
            class="brandMerchantsDataPanel commonUl"
            v-if="hasDataBrandSigned"
          >
            <li class="border-r pr80">
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandSignedData.newSignedQuantity || '--'}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，{{reportUserName}}共计签约了<span class="cls-active">{{brandSignedData.newSignedQuantity || '--'}}个</span>品牌
                </p>
                <div class="clearfix"></div>
              </div>
              <div class="numPanel">
                <span
                  class="numSpan fl"
                  v-if="brandSignedData.maxSignedTotal"
                >
                  {{brandSignedData.maxSignedTotal?Math.round(brandSignedData.maxSignedTotal.substr(0,brandSignedData.maxSignedTotal.length-1)) : '--'}}
                  <span class="unit">万/月</span>
                </span>
                <p class="numSpanText">
                  签约品牌：{{brandSignedData.maxSignedName || '--'}}<br>
                  签约租金：{{brandSignedData.maxSignedRent  || '--'}}元/月/m²<br>
                  租赁面积：{{brandSignedData.maxSignedArea || '--'}}m²<br>
                  租赁周期：{{brandSignedData.maxSignedCycle || '--'}}<br>
                </p>
              </div>
              <div class="clearfix"></div>
              <h3 class="subTitle">新增签约品牌</h3>
              <p
                class="listSpan"
                v-if="brandSignedData.newSignedNameList && brandSignedData.newSignedNameList.length"
              >
                <span
                  v-for="(item,index) in brandSignedData.newSignedNameList"
                  :key="index"
                  @click="toDetail(item.taskId, {
                    contractCode: item.contractCode,
                    contractId: item.contractId
                  })"
                >
                  {{item.brandName || '--'}}
                </span>
              </p>
              <p v-else>--</p>
            </li>
            <li>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandSignedData.nointentionAndShelveQuantity || '--'}}
                  <span class="unit">个</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，{{reportUserName}}共计新确认了<span class="cls-active">{{brandSignedData.nointentionAndShelveQuantity || '--'}}个</span>无意向/已搁置的品牌
                </p>
              </div>
              <div class="clearfix"></div>
              <div class="numPanel">
                <span class="numSpan fl">
                  {{brandSignedData.nointentionAndShelvePercentage*100 || '--'}}
                  <span class="unit">%</span>
                </span>
                <p class="numSpanText">
                  在{{dateIntervalStr||'--'}}，确认无意向/已搁置的品牌数占总跟进品牌数（{{brandSignedData.nointentionShelveTotalQuantity || '--' }}个）的<span class="cls-active">{{brandSignedData.nointentionAndShelvePercentage*100 || '--'}}%</span>
                </p>
              </div>
              <div class="clearfix"></div>
              <h3 class="subTitle">无意向/搁置品牌列表</h3>
              <p
                class="listSpan"
                v-if="brandSignedData.nointentionAndShelveNameAndStateList && brandSignedData.nointentionAndShelveNameAndStateList.length"
              >
                <span
                  v-for="(item,index) in brandSignedData.nointentionAndShelveNameAndStateList"
                  :key="index"
                  @click="toDetail(item.taskId)"
                >
                  {{item.brandName || '--'}}
                  <i
                    class="icon-circle"
                    :style="{background: item.color}"
                  ></i>
                  {{item.taskState || '--'}}
                </span>
              </p>
              <p v-else>--</p>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="jurisdiction-wrap simpleInfoPanel"
        v-if="pageIndex===2"
      >
        <img
          src="@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import echarts from 'echarts'
import { getCookie } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      reportUserName: '',
      loading: false,
      loadingTime: false,
      workReportUserId: 0,
      workReportUser: '',
      pageIndex: 0,
      params: {
        dataType: '',
        endDate: '',
        projectId: '',
        startDate: '',
        tabType: '',
        type: ''
      },
      projectOptions: [],
      filtroDate: '',
      dateInterval: '',
      dateIntervalStr: '',
      tabsOptions: [
        {
          id: 1,
          label: '日报'
        },
        {
          id: 2,
          label: '周报'
        },
        {
          id: 3,
          label: '月报'
        }
      ],
      activeItem: 2, // 1-按天 2-按周3-按月
      dateTextArr: ['昨日', '上周', '上月'],
      dateTextArr1: ['日', '周', '月'],
      dateStr: '', // M月DD日 格式，一般图表用
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        firstDayOfWeek: 6
      },
      datePickerOptions: ['date', 'week', 'month'],
      valueDate: '',
      projectData: [
        {
          index: 0,
          value: 0,
          title: '招商完成率',
          compared: 0
        },
        {
          index: 1,
          value: 0,
          title: '新增签约品牌数量',
          compared: 0
        },
        {
          index: 2,
          value: 0,
          title: '确认无意向/搁置品牌数量',
          compared: 0
        },
        {
          index: 3,
          value: 0,
          title: '新增招商品牌数量',
          compared: 0
        },
        {
          index: 4,
          value: 0,
          title: '进度变化品牌数量',
          compared: 0
        }
      ],
      statesVal: '全部跟进状态',
      statesOption: [
        {
          label: '全部跟进状态'
        },
        // {
        //   label: '待跟进'
        // },
        {
          label: '已联系'
        },
        {
          label: '已看场'
        },
        {
          label: '已报价'
        },
        {
          label: '已预定'
        },
        {
          label: '已签约'
        },
        {
          label: '已搁置'
        },
        {
          label: '无意向'
        }
      ],
      brandMerchantsData: {},
      brandMerchantsDataIsShowMore0: 0,
      memberMerchantsNum: {},
      brandMerchantsDataIsShowMore1: 0,
      memberMerchantsNumList: [],
      memberMerchantsNumListLong: [],
      chartsMember: {},
      merchantsDataArr: [],
      newSignedBrandFiltroOption: ['跟进人员', '品牌业态', '品牌级次'],
      newSignedBrandFiltroValue: '跟进人员',
      noIntentionBrandFiltroValue: '跟进人员',
      newSignedBrandData: {},
      hasDataNwsSignedBrand: false,
      signedBrandList: [],
      chartsNewSignedBrand: {},
      noIntentionBrandData: {},
      noIntentionBrandList: {},
      chartsNoIntentionBrand: {},
      projectProgressFiltroOption: [
        {
          name: '铺位面积',
          label: '面积'
        },
        {
          name: '铺位数量',
          label: '数量'
        }
      ],
      projectProgressFiltroValue: '面积',
      chartsComplexFormat: {},
      chartsComplexFormatData: {},
      chartsComplexFloor: {},
      formatExplain: '',
      floorExplain: '',
      brandSignedData: {},
      hasDataMemberMerchantsNumCharts: false,
      hasDataMemberMerchantsNum: false,
      hasDataChartsFormat: false,
      hasDataChartsFloor: false,
      hasDataBrandSigned: false,
      hasContractRights: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  props: {
    projectId: {
      type: Number
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      if (newVal) {
        this.handleData()
      }
    }
  },
  mounted () {
    this.filtroDate = dayjs().format('YYYY-MM-DD') // datePicker默认选中今天
    const hasPermission = this.user.confIds.filter(item => {
      return item.id === 218
    })
    if (hasPermission.length === 0) {
      this.pageIndex = 2
    } else {
      this.getDataProjList() // 页面接口都依赖于项目id作为入参，在项目接口返回之后再调取接口
      if (this.user.isadmin === 0) { // 如果是总账号，那么可查看“项目招商简报”中所有数据
        this.pageIndex = 0
      } else if ([0, 1].indexOf(this.user.level) !== -1) { // 0总账号，1项目总，2部门管理，3普通
        this.pageIndex = 0
      } else {
        this.pageIndex = 1
      }
    }
    // 直接签约的，点击跳转合同管理，判断有无合同管理权限，有则跳至该项目，无则toast无合同管理权限
    const rightsContractList = this.user.confIds.filter(item => {
      return item.id === 218
    })
    if (rightsContractList.length) {
      this.hasContractRights = true
    } else {
      this.hasContractRights = false
    }
  },
  filters: {
    addSymbol: (val) => {
      return val > 0 ? '+' : '-' + val
    }
  },
  components: {},
  methods: {
    handleTbs (val) {
      this.filtroDate = dayjs().format('YYYY-MM-DD') // datePicker默认选中今天
      this.loadingTime = true
      setTimeout(() => {
        this.loadingTime = false
      }, 1000)
      // clearTimeout(timeHandle)
      this.activeItem = val
      this.handleData()
    },
    handleData () {
      this.params.type = this.activeItem
      this.params.projectId = this.projectId
      this.updateProjectIdFunc()
      let isNow = ''
      if (this.filtroDate) {
        switch (this.activeItem) {
          case 1:
            isNow = dayjs(this.filtroDate).diff(dayjs(), 'day')
            if (isNow === 0) { // 当天
              this.params.startDate = dayjs(this.filtroDate).add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
            } else { // 过去的时间
              this.params.startDate = dayjs(this.filtroDate).startOf('day').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs(this.filtroDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
            this.valueDate = dayjs(this.params.startDate).format('yyyy-MM-DD')
            break
          case 2:
            isNow = dayjs(this.filtroDate).diff(dayjs(), 'week')
            if (isNow === 0) { // 当周
              this.params.startDate = dayjs(this.filtroDate).startOf('week').subtract(1, 'day').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs().format('YYYY-MM-DD HH:mm:ss') // 如果是当周那么选当天
            } else { // 过去的时间
              this.params.startDate = dayjs(this.filtroDate).subtract(1, 'day').startOf('day').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs(this.filtroDate).add(5, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
            this.valueDate = dayjs(this.params.startDate).format('yyyy-MM-DD') + ' 至 ' + dayjs(this.params.endDate).format('yyyy-MM-DD')
            break
          case 3:
            isNow = dayjs(this.filtroDate).diff(dayjs(), 'month')
            if (isNow === 0) { // 当月
              this.params.startDate = dayjs(this.filtroDate).startOf('month').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs().format('YYYY-MM-DD HH:mm:ss') // 如果是当周那么选当天
            } else { // 过去的时间
              this.params.startDate = dayjs(this.filtroDate).startOf('month').startOf('day').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
              this.params.endDate = dayjs(this.filtroDate).endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
            break
          default:
            break
        }
      } else {
        this.params.startDate = dayjs().format('YYYY-MM-DD')
        this.params.endDate = dayjs().format('YYYY-MM-DD')
      }
      if (this.activeItem === 1) {
        this.dateStr = dayjs(this.params.startDate).format('M月DD日')
      } else {
        this.dateStr = dayjs(this.params.startDate).format('M月DD日') + ' - ' + dayjs(this.params.endDate).format('M月DD日')
      }
      // this.params.startDate = '2020-06-01'
      // this.params.endDate = '2020-12-29'
      if (this.activeItem === 1) {
        this.dateInterval = dayjs(this.params.startDate).format('YYYY年MM月DD日')
        this.dateIntervalStr = dayjs(this.params.startDate).format('YYYY年MM月DD日') + '这个时间内'
      } else {
        this.dateInterval = dayjs(this.params.startDate).format('YYYY年MM月DD日') + '-' + dayjs(this.params.endDate).format('YYYY年MM月DD日')
        this.dateIntervalStr = dayjs(this.params.startDate).format('YYYY年MM月DD日') + '至' + dayjs(this.params.endDate).format('YYYY年MM月DD日') + '这个时间内'
      }
      if (this.pageIndex === 0) { // 页面1
        this.getDataChangeDataBrandMerchants() // 品牌招商数据统计
        this.getDataDataTop()
        this.getDataMemberMerchants()
        this.getDataMemberCharts() // 团队成员品牌招商跟进数量对比
        this.getDataNewSignedBrand(this.newSignedBrandFiltroValue) // 签约品牌列表
        this.getDataNoIntentionBrand(this.noIntentionBrandFiltroValue)
        this.getDataProjectProgress(this.projectProgressFiltroValue)
      } else {
        // this.workReportUserId = this.user.userId
        this.getDataChildSigned() // 页面2
      }
    },
    toDetail (id, item) {
      if (item) {
        if (this.hasContractRights) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?menuCode=12&contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&operateStep=3' + '&tokenval=' + getCookie('wisdom_token'))
        } else {
          this.$message({
            type: 'warning',
            message: '您暂无查看合同的权限'
          })
        }
      } else {
        window.open(this.url + '/#/investmentFollowDetail?id=' + id)
      }
    },
    toWorkReport (userId, user) {
      this.reportUserName = user
      this.workReportUserId = userId
      this.workReportUser = user
      this.pageIndex = 1
      document.body.scrollIntoView()
      this.getDataChildSigned() // 页面2
    },
    toProjectReport () {
      this.pageIndex = 0
      this.workReportUserId = this.user.userId
      this.workReportUser = ''
      this.getDataChangeDataBrandMerchants() // 品牌招商数据统计
      this.getDataDataTop()
      this.getDataMemberMerchants()
      this.getDataMemberCharts() // 团队成员品牌招商跟进数量对比
      this.getDataNewSignedBrand(this.newSignedBrandFiltroValue) // 签约品牌列表
      this.getDataNoIntentionBrand(this.noIntentionBrandFiltroValue)
      this.getDataProjectProgress(this.projectProgressFiltroValue)
    },
    getDataProjList () {
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          // if (this.projectOptions.length > 0) {
          //   this.projectId = this.projectOptions[0].id // 默认选中第一个项目
          // }
          this.getProjectIdFunc().then((res) => {
            this.projectId = res.data.data.projectId
            this.handleData() // 拿到第一个项目id再调用接口
          })
        }
        this.loading = false
      })
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.projectId
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    getDataDataTop () {
      this.axios.post(api.bulletinTop, this.params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.projectData[0].value = resData.signedArea
          this.projectData[0].compared = resData.signedAreaRatio
          this.projectData[1].value = resData.newSigned
          this.projectData[1].compared = resData.newSignedRatio
          this.projectData[2].value = resData.noIntentionAndShelve
          this.projectData[2].compared = resData.noIntentionAndShelveRatio
          this.projectData[3].value = resData.newInvestmentBrands
          this.projectData[3].compared = resData.newInvestmentBrandsRatio
          this.projectData[4].value = resData.changeBrands
          this.projectData[4].compared = resData.changeBrandsRatio
        }
      })
    },
    getDataChangeDataBrandMerchants () {
      this.axios.post(api.brandInvestmentData, this.params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.brandMerchantsData = resData
          this.brandMerchantsData.investmentSchedule = this.brandMerchantsData.investmentSchedule.map(item => {
            if (item.taskState === '待跟进') {
              item.color = '#FFA134'
            } else if (item.taskState === '已联系') {
              item.color = '#3494FF'
            } else if (item.taskState === '已看场') {
              item.color = '#26CBE9'
            } else if (item.taskState === '已报价') {
              item.color = '#FEF054'
            } else if (item.taskState === '合同中') {
              item.color = '#FE7429'
            } else if (item.taskState === '已签约') {
              item.color = '#16DA64'
            } else if (item.taskState === '已搁置') {
              item.color = '#C750FF'
            } else {
              item.color = '#999999'
            }
            return item
          })
        }
      })
    },
    getDataMemberMerchants () {
      this.axios.post(api.teamInvestmentData, this.params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.memberMerchantsNum = resData
          this.memberMerchantsNumListLong = this.memberMerchantsNum.investmentDateList.map(item => {
            item.scheduleList = item.brandNames.split(';').map(ele => {
              const tempObj = {
                title: ele
              }
              if (ele.indexOf('待跟进') !== -1) {
                tempObj.color = '#FFA134'
              } else if (ele.indexOf('已联系') !== -1) {
                tempObj.color = '#3494FF'
              } else if (ele.indexOf('已看场') !== -1) {
                tempObj.color = '#26CBE9'
              } else if (ele.indexOf('已报价') !== -1) {
                tempObj.color = '#FEF054'
              } else if (ele.indexOf('合同中') !== -1) {
                tempObj.color = '#FE7429'
              } else if (ele.indexOf('已签约') !== -1) {
                tempObj.color = '#16DA64'
              } else if (ele.indexOf('已搁置') !== -1) {
                tempObj.color = '#C750FF'
              } else {
                tempObj.color = '#999999'
              }
              return tempObj
            })
            return item
          })
          this.memberMerchantsNumList = this.memberMerchantsNumListLong.slice(0, 3)
          if (resData.investmentDateList && resData.investmentDateList.length) {
            this.hasDataMemberMerchantsNum = true
          } else {
            this.hasDataMemberMerchantsNum = false
          }
        }
      })
    },
    handleMemberMerchantsNumList () {
      if (this.memberMerchantsNumList.length > 3) {
        this.memberMerchantsNumList = this.memberMerchantsNumListLong.slice(0, 3)
      } else {
        this.memberMerchantsNumList = this.memberMerchantsNumListLong.map(item => { return item })
      }
    },
    getOptionChartsBar () {
      const option = {
        color: ['#26CBE9'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          type: 'plain',
          bottom: -3,
          itemGap: 15,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '15%',
          top: '3%',
          show: false,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#fff'
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 10,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(226,226,226,.1'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '50',
            data: []
          }
        ]
      }
      return option
    },
    getDataMemberCharts () {
      const params = {}
      Object.assign(params, this.params)
      params.dataType = this.statesVal
      this.axios.post(api.teamInvestmentList, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (
            resData.followBrandComparedScheduleList.labels &&
            resData.followBrandComparedScheduleList.labels.length
          ) {
            this.hasDataMemberMerchantsNumCharts = true
            this.$nextTick(() => {
              this.chartsMember = echarts.init(document.getElementById('chartsMember'))
              this.chartsMember.setOption(this.getOptionChartsBar())
              this.chartsMember.setOption({
                tooltip: {
                  formatter: this.dateStr + '<br>跟进品牌数：{c}'
                },
                yAxis: [
                  {
                    axisLabel: {
                      formatter: '{value}个'
                    }
                  }
                ],
                xAxis: [
                  {
                    data: resData.followBrandComparedScheduleList.labels
                  }
                ],
                series: [
                  {
                    data: resData.followBrandComparedScheduleList.values
                  }
                ]
              })
              window.addEventListener('resize', () => {
                this.chartsMember.resize()
              })
            })
          } else {
            this.hasDataMemberMerchantsNumCharts = false
          }
        }
      })
    },
    getOptionChartsPie () {
      const option = {
        title: {
          zlevel: 0,
          text: '',
          top: 'middle',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 35
          }
        },
        graphic: {
          type: 'text',
          zlevel: 0,
          top: '55%',
          left: 'center',
          style: {
            text: '',
            fill: '#fff',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'item',
          textStyle: {
            align: 'left'
          },
          formatter: this.dateStr + '<br>{b}：{c} 个占比 {d}% '
        },
        legend: {
          // orient: 'vertical',
          x: 'center',
          bottom: -5,
          data: [],
          color: '#fff',
          icon: 'square',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['30%', '60%'],
            avoidLabelOverlap: false,
            zlevel: 1,
            hoverOffset: 11,
            label: { //  饼图图形上的文本标签
              normal: { // normal 是图形在默认状态下的样式
                show: false,
                position: 'center',
                fontSize: 16,
                fontWeight: 'bold'
                // formatter: '{c}' // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 50,
                shadowColor: '#444'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: []
          }
        ],
        color: ['#518BDA', '#38B3EE', '#22D6FE']
      }
      return option
    },
    getDataNewSignedBrand (tabType) {
      const params = {}
      Object.assign(params, this.params)
      params.tabType = tabType
      this.axios.post(api.newSignedDistributed, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          if (resData.chartItemList && resData.chartItemList.length) {
            this.hasDataNwsSignedBrand = true
            this.newSignedBrandData = resData
            this.signedBrandList = resData.brandListScheduleList.slice(0, 5)
            this.$nextTick(() => {
              this.chartsNewSignedBrand = echarts.init(document.getElementById('chartsNewSignedBrand'))
              this.chartsNewSignedBrand.setOption(this.getOptionChartsPie())
              const labelsArr = resData.chartItemList[0].labels
              let maxItem = {
                index: 0,
                label: '',
                value: 0
              }
              if (resData.chartItemList[0].values && resData.chartItemList[0].values.length) {
                maxItem.label = resData.chartItemList[0].labels[0]
                maxItem.value = resData.chartItemList[0].values[0]
              }
              const seriesData = resData.chartItemList[0].values.map((item, index) => {
                const tempObj = {
                  value: item,
                  name: labelsArr[index]
                }
                if (maxItem.value < item) {
                  maxItem = tempObj
                  maxItem.index = index
                }
                return tempObj
              })
              this.chartsNewSignedBrand.setOption({
                legend: {
                  data: labelsArr
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
              this.chartsNewSignedBrand.dispatchAction({
                type: 'highlight',
                seriesIndex: maxItem.index,
                name: maxItem.label
              })
              this.chartsNewSignedBrand.dispatchAction({
                type: 'showTip',
                seriesIndex: maxItem.index,
                name: maxItem.label
              })
              window.addEventListener('resize', () => {
                this.chartsNewSignedBrand.resize()
              })
            })
          } else {
            this.hasDataNwsSignedBrand = false
          }
        }
      })
    },
    handleTbsLenght (index) {
      if (index === 0) {
        if (this.signedBrandList.length > 5) {
          this.signedBrandList = this.newSignedBrandData.brandListScheduleList.slice(0, 5)
        } else {
          this.signedBrandList = this.newSignedBrandData.brandListScheduleList
        }
      } else if (index === 1) {
        if (this.noIntentionBrandList.length > 5) {
          this.noIntentionBrandList = this.noIntentionBrandData.brandListScheduleList.slice(0, 5)
        } else {
          this.noIntentionBrandList = this.noIntentionBrandData.brandListScheduleList
        }
      }
    },
    getDataNoIntentionBrand (tabType) {
      const params = {}
      Object.assign(params, this.params)
      params.tabType = tabType
      this.axios.post(api.noIntentionAndShelvedDistributed, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.noIntentionBrandData = resData
          this.noIntentionBrandList = resData.brandListScheduleList.slice(0, 5)
          if (resData.chartItemList && resData.chartItemList.length) {
            this.$nextTick(() => {
              this.chartsNoIntentionBrand = echarts.init(document.getElementById('chartsNoIntentionBrand'))
              this.chartsNoIntentionBrand.setOption(this.getOptionChartsPie())
              const labelsArr = resData.chartItemList[0].labels
              let maxItem = {
                index: 0,
                label: '',
                value: 0
              }
              if (resData.chartItemList[0].values && resData.chartItemList[0].values.length) {
                maxItem.label = resData.chartItemList[0].labels[0]
                maxItem.value = resData.chartItemList[0].values[0]
              }
              const seriesData = resData.chartItemList[0].values.map((item, index) => {
                const tempObj = {
                  value: item,
                  name: labelsArr[index]
                }
                if (maxItem.value < item) {
                  maxItem = tempObj
                  maxItem.index = index
                }
                return tempObj
              })
              this.chartsNoIntentionBrand.setOption({
                legend: {
                  data: labelsArr
                },
                series: [
                  {
                    data: seriesData
                  }
                ]
              })
              this.chartsNoIntentionBrand.dispatchAction({
                type: 'highlight',
                seriesIndex: maxItem.index,
                name: maxItem.label
              })
              this.chartsNoIntentionBrand.dispatchAction({
                type: 'showTip',
                seriesIndex: maxItem.index,
                name: maxItem.label
              })
              window.addEventListener('resize', () => {
                this.chartsNoIntentionBrand.resize()
              })
            })
          }
        }
      })
    },
    getDataProjectProgress (dataType) {
      const params = {
      }
      Object.assign(params, this.params)
      params.dataType = dataType
      this.axios.post(api.projectInvestmentData, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          const legendData = []
          legendData.push('招商完成率')
          legendData.push('相对' + this.dateTextArr[this.activeItem - 1])
          if (resData.industryRatioList.industryName && resData.industryRatioList.industryName.length) {
            this.hasDataChartsFormat = true
            const setScroll = Boolean(resData.industryRatioList.industryName.length > 5)
            this.$nextTick(() => {
              this.chartsComplexFormat = echarts.init(document.getElementById('chartsComplexFormat'))
              this.chartsComplexFormat.setOption(this.getOptionChartsBar())
              this.chartsComplexFormat.setOption({
                legend: {
                  data: legendData
                },
                grid: {
                  right: '10%',
                  show: false,
                  containLabel: true
                },
                tooltip: {
                  formatter: (params) => {
                    let tempStr = this.dateStr + '<br />'
                    tempStr += params[0].name + params[0].seriesName + params[0].data + '% (' + params[1].seriesName
                    if (params[1].data > 0) {
                      tempStr += '+' + params[1].data + '%)'
                    } else if (params[1].data < 0) {
                      tempStr += '-' + params[1].data + '%)'
                    } else {
                      tempStr += '不变)'
                    }
                    return tempStr
                  }
                },
                yAxis: [
                  {
                    axisLabel: {
                      formatter: '{value}%'
                    }
                  }
                ],
                xAxis: [
                  {
                    data: resData.industryRatioList.industryName
                  }
                ],
                series: [
                  {
                    name: legendData[0],
                    data: resData.industryRatioList.industryProportion
                  },
                  {
                    name: legendData[1],
                    data: resData.industryRatioList.industryRatio,
                    type: 'line',
                    color: '#FFA134',
                    symbolSize: 8,
                    lineStyle: {
                      color: '#FFA134'
                    }
                  }
                ]
              })
              console.log('setScroll', setScroll)
              if (setScroll) {
                let tempObj = {}
                tempObj = {
                  xAxis: {
                    axisLabel: { interval: 0 }
                  },
                  grid: {
                    bottom: 35
                  },
                  legend: {
                    bottom: 10
                  },
                  dataZoom: [
                    {
                      type: 'slider',
                      show: true,
                      xAxisIndex: [0],
                      bottom: 3,
                      start: 0,
                      end: 45,
                      height: 6,
                      moveHandleSize: 2,
                      backgroundColor: 'none',
                      fillerColor: '#3c3e44',
                      textStyle: false,
                      borderColor: 'none',
                      handleStyle: {
                        color: '#3c3e44'
                      },
                      handleIcon: 'path://M512,512m-448,0a448,448,0,1,0,896,0a448,448,0,1,0,-896,0Z'
                    },
                    {
                      type: 'inside',
                      xAxisIndex: [0],
                      start: 1,
                      end: 45
                    }
                  ]
                }
                this.chartsComplexFormat.setOption(tempObj)
              }
              window.addEventListener('resize', () => {
                this.chartsComplexFormat.resize()
              })
            })
          } else {
            this.hasDataChartsFormat = false
          }
          if (resData.floorRatioList.floorProportion && resData.floorRatioList.floorProportion.length) {
            this.hasDataChartsFloor = true
            this.$nextTick(() => {
              this.chartsComplexFloor = echarts.init(document.getElementById('chartsComplexFloor'))
              this.chartsComplexFloor.setOption(this.getOptionChartsBar())
              this.chartsComplexFloor.setOption({
                tooltip: {
                  formatter: (params) => {
                    let tempStr = this.dateStr + '<br />'
                    tempStr += params[0].name + params[0].seriesName + params[0].data + '% (' + params[1].seriesName
                    if (params[1].data > 0) {
                      tempStr += '+' + params[1].data + '%)'
                    } else if (params[1].data < 0) {
                      tempStr += '-' + params[1].data + '%)'
                    } else {
                      tempStr += '不变)'
                    }
                    return tempStr
                  }
                },
                legend: {
                  data: legendData
                },
                yAxis: [
                  {
                    axisLabel: {
                      formatter: '{value}%'
                    }
                  }
                ],
                xAxis: [
                  {
                    data: resData.floorRatioList.floorName
                  }
                ],
                series: [
                  {
                    name: legendData[0],
                    data: resData.floorRatioList.floorProportion
                  },
                  {
                    name: legendData[1],
                    data: resData.floorRatioList.floorRatio,
                    type: 'line',
                    color: '#FFA134',
                    symbolSize: 8,
                    lineStyle: {
                      color: '#FFA134'
                    }
                  }
                ]
              })
              window.addEventListener('resize', () => {
                this.chartsComplexFloor.resize()
              })
            })
          } else {
            this.hasDataChartsFloor = false
          }
          let flagIndustry = 0
          let flagFloor = 0
          const trendArr = ['上升', '下降', '持平']
          const classArr = ['cls-green', 'cls-orange', '']
          if (resData.industryRatioList.maxIndustryRatio > 0) {
            flagIndustry = 0
          } else if (resData.industryRatioList.maxIndustryRatio < 0) {
            flagIndustry = 1
          } else {
            flagIndustry = 2
          }
          if (resData.industryRatioList.maxIndustryName) {
            this.formatExplain = `
            <span class="cls-active"> ${resData.industryRatioList.maxIndustryName}</span>
            招商进度变化最大，招商完成率 ${resData.industryRatioList.maxIndustryProportion}%
            （相对${this.dateTextArr[this.activeItem - 1]} <span class="${classArr[flagIndustry]}">
            ${trendArr[flagIndustry]}
            ${resData.industryRatioList.maxIndustryRatio === 0 ? '' : resData.industryRatioList.maxIndustryRatio + '%'}
            </span>)`
          } else {
            this.formatExplain = ''
          }
          if (resData.floorRatioList.maxFloorRatio > 0) {
            flagFloor = 0
          } else if (resData.industryRatioList.maxIndustryRatio < 0) {
            flagFloor = 1
          } else {
            flagFloor = 2
          }
          if (resData.floorRatioList.maxFloorName) {
            this.floorExplain = `
            <span class="cls-active"> ${resData.floorRatioList.maxFloorName}</span>
            招商进度变化最大，招商完成率 ${resData.floorRatioList.maxFloorProportion}%
            （相对${this.dateTextArr[this.activeItem - 1]} <span class="${classArr[flagFloor]}">
            ${trendArr[flagFloor]}
            ${flagFloor === 2 ? '' : resData.floorRatioList.maxFloorRatio + '%'}
            </span>)`
          } else {
            this.floorExplain = ''
          }
        }
      }, rej => {
        this.hasDataChartsFormat = false
        this.hasDataChartsFloor = false
        console.log(rej)
      }).catch(err => {
        this.hasDataChartsFormat = false
        this.hasDataChartsFloor = false
        console.log(err)
      })
    },
    getDataChildSigned () {
      this.hasDataBrandSigned = false
      const params = {
        dateType: this.activeItem,
        endDate: this.params.endDate,
        startDate: this.params.startDate,
        projectId: this.params.projectId,
        userId: this.workReportUserId ? this.workReportUserId : this.user.userId
      }
      this.axios.post(api.noIntentionAndShelveStatistics, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.brandSignedData = resData
          // 无意向、已搁置品牌数:nointentionAndShelveQuantity
          // 账号跟进总数:nointentionShelveTotalQuantity
          // 无意向、已搁置品牌:nointentionAndShelveNameAndStateList
          // 无意向、已搁置品牌数比例:nointentionAndShelvePercentage
          this.brandSignedData.nointentionAndShelveNameAndStateList = resData.nointentionAndShelveNameAndStateList.map(item => {
            if (item.taskState === '待跟进') {
              item.color = '#FFA134'
            } else if (item.taskState === '已联系') {
              item.color = '#3494FF'
            } else if (item.taskState === '已看场') {
              item.color = '#26CBE9'
            } else if (item.taskState === '已报价') {
              item.color = '#FEF054'
            } else if (item.taskState === '合同中') {
              item.color = '#FE7429'
            } else if (item.taskState === '已签约') {
              item.color = '#16DA64'
            } else if (item.taskState === '已搁置') {
              item.color = '#C750FF'
            } else {
              item.color = '#999999'
            }
            return item
          })
          this.axios.post(api.brandSignedStatistics, params).then(res => {
            if (res.data.code === 0 && res.data.data) {
              const resData = res.data.data
              Object.assign(this.brandSignedData, resData)
              this.hasDataBrandSigned = true
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        }
      })
      this.axios.post(api.brandInvestmentStatistics, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.brandMerchantsData = resData
          this.brandMerchantsData.investmentSchedule = this.brandMerchantsData.merchantsChangeNameAndStateList.map(item => {
            if (item.taskState === '待跟进') {
              item.color = '#FFA134'
            } else if (item.taskState === '已联系') {
              item.color = '#3494FF'
            } else if (item.taskState === '已看场') {
              item.color = '#26CBE9'
            } else if (item.taskState === '已报价') {
              item.color = '#FEF054'
            } else if (item.taskState === '合同中') {
              item.color = '#FE7429'
            } else if (item.taskState === '已签约') {
              item.color = '#16DA64'
            } else if (item.taskState === '已搁置') {
              item.color = '#C750FF'
            } else {
              item.color = '#999999'
            }
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.merchantReports
  color #fff
  .wrap
    margin 0 0 0 204px
    width calc(100% - 226px)
    overflow hidden
    .tabsPanel
      width auto
      li
        background #23252C
        width 194px
        height 49px
        line-height 49px
        text-align center
        color #fff
        display inline-block
        border-radius 2px 2px 0px 0px
        font-size 18px
        cursor pointer
        margin-right 8px
      li.activeItem
        color #FFA134
        background #272930
    .filtroPanel
      margin 0
      top 0px
      position relative
      li
        display inline-block
        margin-left 10px
        line-height 48px
    .box624
      height 624px
    .simpleInfoPanel
      width 100%
      font-size 14px
      margin-bottom 16px
      text-align center
      position relative
      padding-bottom 36px
      .chartsNoData
        height 222px
      .chartsBox
        width 100%
        min-height 266px
      #chartsMember
        margin-bottom 55px
      .w-title-gradients
        text-align left
        margin-top 36px
      .showMoreBtn
        width 125px
        height 35px
        position absolute
        cursor pointer
        right 92px
        top 260px
      .projectDataPanel
        margin 39px auto 28px auto
        li:last-child
          border none
        li
          border-right 1px dashed rgba(255, 255, 255, 0.1)
          width 19.8%
          display inline-block
          h2
            font-size 32px
            margin 0 0 11px 0
            span
              font-size 14px
          .liTitle
            margin-bottom 10px
            &:after
              content ''
              width 100px
              margin 10px auto
              height 1px
              display block
              border-bottom 1px dashed rgba(255, 255, 255, 0.1)
      .brandMerchantsDataPanel
        li
          p
            font-size 16px
            line-height 24px
            margin 0 0 22px 0
      .commonUl
        padding 0 106px
        li
          font-weight 500
          box-sizing border-box
          text-align left
          display inline-block
          vertical-align top
          width 48%
        li:last-child
          padding-left 106px
      .subTitle
        font-size 16px
        line-height 24px
        margin-bottom 22px
        span
          display inline-block
          font-size 12px
          margin-left 5px
          cursor pointer
      .wrapBox
        text-align left
        box-sizing border-box
        width 100%
        padding 0 108px
      .numPanel
        margin-top 50px !important
      .numSpan
        margin 0 53px 40px 0
        text-align center
        display inline-block
        font-size 44px
        width 124px
        height 124px
        line-height 124px
        background #272930
        border 1px solid rgba(255, 255, 255, 0.3)
        box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
        border-radius 12px
        .unit
          font-size 15px
          position relative
          left -16px
      .numSpanText
        width 75%
        height 124px
        margin-top 50px !important
        display table-cell
        vertical-align middle
      .listSpan
        span
          display inline-block
          cursor pointer
          padding 10px 24px
          background rgba(255, 255, 255, 0.05)
          border-radius 17px
          margin 5px
          overflow hidden
          font-size 14px
      .showLess
        height 162px !important
        overflow hidden
      .icon-circle
        display inline-block
        width 12px
        height 12px
        border-radius 50%
        position relative
        top 5px
        margin 0 7px 0 43px
        &:before
          display inline-block
          content ''
          width 1px
          height 15px
          background rgba(255, 255, 255, 0.3)
          position relative
          right 19px
          bottom 2px
      .commonTbs
        margin-bottom 18px
        td
          border 1px solid rgba(226, 226, 226, 0.1)
          padding 16px 20px
          span
            display inline-block
        td.tbsTh
          text-align center
          background #303238
    .simpleInfoPanel>>>
      .mt-35
        margin-top 35px
      .wrapPer50
        width 50%
      .wrapPer46
        width 46%
      .filtroRight
        margin-top 34px
        margin-right 40px
      .commonBtn
        padding 10px 20px
        border 1px solid #FFA134
        color #FFA134
        cursor pointer
        overflow hidden
      .cls-blue
        background #3494FF
      .noBorder-r
        border-right none !important
      .noBorder-l
        border-left none !important
      .cls-yellow, background #FFA134, .cls-green
        color #07C43E
      .cls-orange
        color #FF5A06
      .cls-active
        color #FFA134
      .chartsExplain
        font-size 18px
        text-align center
        margin 43px 0 20px 0
      .cls-Explain-comparison
        color #FF3D3D
.details-no-data
  margin 30px 0 50px 0
  img
    margin-top 50px
.border-r
  border-right 1px solid rgba(226, 226, 226, 0.1)
.pr80
  padding 0 80px 0 0
.border-r-x
  position relative
  &:after
    content ''
    width 1px
    min-height 551px
    position absolute
    right 0px
    top 200px
    background rgba(226, 226, 226, 0.1)
.title-flex
  display flex
  >div
    flex 1
</style>
